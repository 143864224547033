
.apexcharts-align-center{
  justify-content: left !important;
}

@media screen and (max-width: 539px) {

  .high_chart_maps{
    width: 110%;
  }

  .filter_prov_pie{
    width: 100% !important;
  }

  .filter_sert_pie{
    width: 100% !important;
  }

  .filter_sert{
    width: 100% !important;
  }
  
  .getLabel {
    background: #001c54;
    width: max-content;
    border-radius: 8px;
    padding: 10px;
    font-family: "Poppins", sans-serif !important;
    float: right;
  }

  .pt-getLabel {
    padding-top: 5px;
  }

  .logo-labelmaps {
    width: 65px !important;
  }
  
  .title-labelmaps {
    color: #001c54 !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 800 !important;
    margin-top: 12px;
  }
  
  .open-map {
    background: #48aa72;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 4;
    color: #ffff;
    margin-bottom: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .toggle_ul {
    font-size: small;
    margin-left: 40%;
    cursor: pointer;
  }
  .color-li {
    background-color: #48aa72;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 4px;
    color: #ffff;
  }
  
  .label-map {
    width: 105px;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    background-color: #001c54;
  }
  
  .get-label {
    background-color: #001c54 !important;
    color: #fff !important;
    font-size: small !important;
  }
  
  .img-bg {
    opacity: 0.6;
  }
  
  .bg-image {
    display: flex;
    align-items: center;
    background-color: #000;
  }
  
  .txt-bg-image {
    font-family: "Poppins", sans-serif !important;
    position: absolute;
    color: #fff;
    font-weight: bolder !important;
    font-size: 27px;
    margin-left: 7% !important;
  }
  
  .title {
    color: #fff;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    padding: 20%;
  }
  
  .background-landing {
    background-color: #001c54 !important;
    width: 100% !important;
    height: 103%;
    padding: 20px;
  }
  
  .text-background {
    color: #fff !important;
    text-align: center !important;
    padding: 20px;
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .button-background {
    text-align: center !important;
    align-items: center !important;
  }
  .btn-getstarted {
    width: 42%;
    height: 40px;
    border-radius: 10px;
    color: #001c54;
    font-size: 12px;
    font-weight: 700;
    border: none;
  }
  
  .background-place {
    background-color: #fff !important;
    width: 100% !important;
    height: 103%;
    /* padding: 20px; */
  }
  
  .sponsor-mcd {
    width: 35%;
  }
  
  .sponsor-pertamina {
    width: 35%;
  }
  .sponsor-garuda {
    width: 35%;
  }
  .sponsor-santika {
    width: 35%;
  }
  
  .shape {
    width: 35%!important;
    height: 137px;
    border-radius: 16px;
    background-color: #001c54;
    text-align: center;
    font-size: 12px !important;
    padding: 22px !important;
    align-items: center;
    display: grid;

  }
  
  .customer-feedback-wrapper {
    height: 480px !important;
    background: #001c54;
    overflow: hidden !important;
  }
  
  .feedback-title {
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-family: "Poppins", sans-serif !important;
  }
  
  .feedback-card {
    background: #fff;
    border-radius: 10px !important;
    margin: 30px !important;
    height: 350px !important;
  }
  .profile {
    display: flex;
    justify-content: center;
  }
  
  .img-profile {
    width: 80px;
    height: 80px;
    border-radius: 60px;
    justify-content: center !important;
  }
  
  .user-name,
  .user-job {
    text-align: center;
    margin: 5px;
    font-family: "Poppins", sans-serif !important;
  }
  
  .button-feedback {
    width: 120px;
    border-radius: 30px !important;
    font-size: 10px !important;
    height: 35px;
    position: absolute;
    margin-right: 40px;
    right: 0px;
    font-family: "Poppins", sans-serif;
  }
  
  .text-description {
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    padding: 7px;
  }
  
  .viewmore {
    padding: 25px;
  }
  
  .text-customer {
    font-size: 25px;
    font-weight: bold;
    margin-left: 30px;
  }
  .img-quote {
    position: absolute;
    margin-left: 30%;
  }
  
  .text-landing {
    color: #001c54;
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }

  .text-landing-grafik {
    color: #001c54;
    font-size: 18px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-workflow {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-workflow2 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-step{
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
  }
  .text-send,
  .text-label,
  .text-checkbox,
  .textfooter {
    font-family: "Poppins", sans-serif !important;
  }
  
  .btn-landing1 {
    background-color: #d1d1d1 !important;
    color: #001c54 !important;
    border-radius: 30px !important;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    border:none;
  }
  
  .btn-landing2 {
    background-color: #001c54 !important;
    color: #fff !important;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 30px !important;
    border:none;
  }
  
  .btnMessage {
    margin-top: 20px;
    border-radius: 20px !important;
    background-color: #001c54 !important;
    color: #fff !important;
  }
  .input {
    color: #000;
    margin-top: 10%;
    /* width: 70%; */
    height: 90%;
    border-color: #fff;
    background-color: #fff;
  }
  
  .footer {
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: #001c54;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: space-between;
  }
  /* .textfooter {
    background-color: rgba(0, 0, 0, 0.2);
  } */
  
  .img-person {
    margin-top: 10%;
  }
  
  .img-person {
    width: 100%;
    height: 100%;
  }
  
  /* welcome */
  .footer-margin {
    margin-bottom: 4px;
  }
  
  .partner {
    display: flex !important;
    justify-content: center !important;
  }
  
  .partner img {
    width: 120px !important;
  }
  
}

@media screen and (min-width: 540px) and (max-width: 768px) { 

  .high_chart_maps{
    width: 110%;
  }

  .filter_prov_pie{
    width: 100% !important;
  }

  .filter_sert_pie{
    width: 100% !important;
  }

  .filter_sert{
    width: 100% !important;
  }

  .logo-labelmaps {
    width: 65px !important;
  }
  
  .title-labelmaps {
    color: #001c54 !important;
    /* margin-left: -10px !important; */
    font-family: "Poppins", sans-serif !important;
    font-weight: 800 !important;
    /* font-size: 18px !important; */
    margin-top: 12px;
  }
  
  .open-map {
    background: #48aa72;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 4;
    color: #ffff;
    margin-bottom: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .getLabel {
    background: #001c54;
    width: max-content;
    border-radius: 8px;
    padding: 10px;
    margin: 10px -10px 10px 10px;
    font-family: "Poppins", sans-serif !important;
    /* margin-left: 15px; */
    display: table-cell;
  }

  .pt-getLabel {
    padding-top: 5px;
  }

  .toggle_ul {
    font-size: small;
    margin-left: 40%;
    cursor: pointer;
  }
  .color-li {
    background-color: #48aa72;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 4px;
    color: #ffff;
  }
  
  .label-map {
    width: 105px;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    background-color: #001c54;
  }
  
  .get-label {
    background-color: #001c54 !important;
    color: #fff !important;
    font-size: small !important;
  }
  
  .img-bg {
    opacity: 0.6;
  }
  
  .bg-image {
    display: flex;
    align-items: center;
    background-color: #000;
  }
  
  .txt-bg-image {
    font-family: "Poppins", sans-serif !important;
    position: absolute;
    color: #fff;
    font-weight: bolder !important;
    font-size: 43px;
    margin-left: 7% !important;
  }
  
  .title {
    color: #fff;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    padding: 20%;
  }
  
  .background-landing {
    background-color: #001c54 !important;
    width: 100% !important;
    height: 103%;
    padding: 20px;
  }
  
  .text-background {
    color: #fff !important;
    text-align: center !important;
    padding: 20px;
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .button-background {
    text-align: center !important;
    align-items: center !important;
  }
  .btn-getstarted {
    width: 20%;
    height: 40px;
    border-radius: 6px;
    color: #001c54;
    font-size: 12px;
    font-weight: 700;
    border: none;
  }
  
  .background-place {
    background-color: #fff !important;
    width: 100% !important;
    height: 103%;
    padding: 20px;
  }
  
  .sponsor-mcd {
    width: 35%;
  }
  
  .sponsor-pertamina {
    width: 35%;
  }
  .sponsor-garuda {
    width: 35%;
  }
  .sponsor-santika {
    width: 35%;
  }
  
  .shape {
    width: 30%!important;
    height: 137px;
    border-radius: 16px;
    background-color: #001c54;
    text-align: center;
    font-size: 12px !important;
    padding: 22px !important;
    align-items: center;
    display: grid;
  }
  
  .customer-feedback-wrapper {
    height: 480px !important;
    background: #001c54;
    overflow: hidden !important;
  }
  
  .feedback-title {
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-family: "Poppins", sans-serif !important;
  }
  
  .feedback-card {
    background: #fff;
    border-radius: 10px !important;
    margin: 30px !important;
    height: 350px !important;
  }
  .profile {
    display: flex;
    justify-content: center;
  }
  
  .img-profile {
    width: 80px;
    height: 80px;
    border-radius: 60px;
    justify-content: center !important;
  }
  
  .user-name,
  .user-job {
    text-align: center;
    margin: 5px;
    font-family: "Poppins", sans-serif !important;
  }
  
  .button-feedback {
    width: 120px;
    border-radius: 30px !important;
    font-size: 10px !important;
    height: 35px;
    position: absolute;
    margin-right: 40px;
    right: 0px;
    font-family: "Poppins", sans-serif;
  }
  
  .text-description {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    padding: 30px;
  }
  
  .viewmore {
    padding: 25px;
  }
  
  .text-customer {
    font-size: 25px;
    font-weight: bold;
    margin-left: 30px;
  }
  .img-quote {
    position: absolute;
    margin-left: 30%;
  }
  
  .text-landing {
    color: #001c54;
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-workflow {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-workflow2 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-step{
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
  }
  .text-send,
  .text-label,
  .text-checkbox,
  .textfooter {
    font-family: "Poppins", sans-serif !important;
  }
  
  .btn-landing1 {
    background-color: #d1d1d1 !important;
    color: #001c54 !important;
    border-radius: 30px !important;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    border: none;
  }
  
  .btn-landing2 {
    background-color: #001c54 !important;
    color: #fff !important;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 30px !important;
    border: none;
  }
  
  .btnMessage {
    margin-top: 20px;
    border-radius: 20px !important;
    background-color: #001c54 !important;
    color: #fff !important;
  }
  .input {
    color: #000;
    margin-top: 10%;
    /* width: 70%; */
    height: 90%;
    border-color: #fff;
    background-color: #fff;
  }
  
  .footer {
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: #001c54;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: space-between;
  }
  /* .textfooter {
    background-color: rgba(0, 0, 0, 0.2);
  } */
  
  .img-person {
    margin-top: 10%;
  }
  
  .img-person {
    width: 100%;
    height: 100%;
  }
  
  /* welcome */
  .footer-margin {
    margin-bottom: 4px;
  }
  
  .partner {
    display: flex !important;
    justify-content: center !important;
  }
  
  .partner img {
    width: 120px !important;
  }
}

@media screen and (min-width: 768px) {
  
  .filter_prov_pie{
    width: 100% !important;
  }

  .filter_sert_pie{
    width: 100% !important;
  }

  .filter_sert{
    width: 100% !important;
  }
  
  .labelMap {
    display: flex !important;
    padding: 5;
    margin-left: -25px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  
  .logo-labelmaps {
    width: 65px !important;
  }
  
  .title-labelmaps {
    color: #001c54 !important;
    margin-left: -10px !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 800 !important;
    font-size: 18px !important;
  }
  
  .open-map {
    background: #48aa72;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 4;
    color: #ffff;
    margin-bottom: 20px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .getLabel {
    background: #001c54;
    padding: 5;
    margin-right: 20px !important;
    border-radius: 8px;
    margin: 10px -10px 10px 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .toggle_ul {
    font-size: small;
    margin-left: 40%;
    cursor: pointer;
  }
  .color-li {
    background-color: #48aa72;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 4px;
    color: #ffff;
  }
  
  .label-map {
    width: 105px;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
    background-color: #001c54;
  }
  
  .get-label {
    background-color: #001c54 !important;
    color: #fff !important;
    font-size: small !important;
  }
  
  .img-bg {
    opacity: 0.6;
  }
  
  .bg-image {
    display: flex;
    align-items: center;
    background-color: #000;
  }
  
  .txt-bg-image {
    font-family: "Poppins", sans-serif !important;
    position: absolute;
    color: #fff;
    font-weight: bolder !important;
    font-size: 50px;
    /* margin-left: 26% !important; */
  }
  
  .title {
    color: #fff;
    justify-content: center !important;
    text-align: center !important;
    align-items: center !important;
    padding: 20%;
  }
  
  .background-landing {
    background-color: #001c54 !important;
    width: 100% !important;
    height: 103%;
    padding: 20px;
  }
  
  .text-background {
    color: #fff !important;
    text-align: center !important;
    padding: 20px;
    font-size: 15px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .button-background {
    text-align: center !important;
    align-items: center !important;
  }
  .btn-getstarted {
    width: 12%;
    height: 40px;
    border-radius: 6px;
    color: #001c54;
    font-size: 12px;
    font-weight: 700;
    border: none;
  }
  
  .background-place {
    background-color: #fff !important;
    width: 100% !important;
    height: 103%;
    padding: 20px;
  }
  
  .sponsor-mcd {
    width: 100px;
  }
  
  .sponsor-pertamina {
    width: 100px;
  }
  .sponsor-garuda {
    width: 100px;
  }
  .sponsor-santika {
    width: 100px;
  }
  
  .shape {
    width: 120px !important;
    height: 120px;
    border-radius: 16px;
    background-color: #001c54;
    text-align: center;
    font-size: 12px !important;
    padding: 13px !important;
    align-items: center;
    display: grid;
  }
  
  .customer-feedback-wrapper {
    height: 480px !important;
    background: #001c54;
    overflow: hidden !important;
  }
  
  .feedback-title {
    text-align: center;
    color: #fff;
    margin-top: 30px;
    font-family: "Poppins", sans-serif !important;
  }
  
  .feedback-card {
    background: #fff;
    border-radius: 10px !important;
    margin: 30px 150px 80px 150px !important;
    /* height: 350px !important; */
  }
  .profile {
    display: flex;
    justify-content: center;
  }
  
  .img-profile {
    width: 80px;
    height: 80px;
    border-radius: 60px;
    justify-content: center !important;
  }
  
  .user-name,
  .user-job {
    text-align: center;
    margin: 5px;
    font-family: "Poppins", sans-serif !important;
  }
  
  .button-feedback {
    width: 120px;
    border-radius: 30px !important;
    font-size: 10px !important;
    height: 35px;
    position: absolute;
    margin-right: 40px;
    right: 0px;
    font-family: "Poppins", sans-serif;
  }
  
  .text-description {
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    padding: 30px;
  }
  
  .viewmore {
    padding: 25px;
  }
  
  .text-customer {
    font-size: 25px;
    font-weight: bold;
    margin-left: 30px;
  }
  .img-quote {
    position: absolute;
    margin-left: 30%;
  }
  
  .text-landing {
    color: #001c54;
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-workflow {
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-workflow2 {
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }
  
  .text-step{
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
  }
  .text-send,
  .text-label,
  .text-checkbox,
  .textfooter {
    font-family: "Poppins", sans-serif !important;
  }
  
  .btn-landing1 {
    background-color: #d1d1d1 !important;
    color: #001c54 !important;
    border-radius: 30px !important;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    border: none;
  }
  
  .btn-landing2 {
    background-color: #001c54 !important;
    color: #fff !important;
    font-weight: bold;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 30px !important;
    border: none;
  }
  
  .btnMessage {
    margin-top: 20px;
    border-radius: 20px !important;
    background-color: #001c54 !important;
    color: #fff !important;
  }
  .input {
    color: #000;
    margin-top: 10%;
    /* width: 70%; */
    height: 90%;
    border-color: #fff;
    background-color: #fff;
  }
  
  .footer {
    color: #fff;
    width: 100%;
    height: 100%;
    background-color: #001c54;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: space-between;
  }
  /* .textfooter {
    background-color: rgba(0, 0, 0, 0.2);
  } */
  
  .img-person {
    margin-top: 10%;
  }
  
  .img-person {
    width: 100%;
    height: 100%;
  }
  
  /* welcome */
  .footer-margin {
    margin-bottom: 4px;
  }
  
  .partner {
    display: flex !important;
    justify-content: center !important;
  }
  
  .partner img {
    width: 120px !important;
  }
  
}

@media screen and (min-width: 1024px) {
  
  #container {
    height: 500px;
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
  }

  .loading {
      margin-top: 10em;
      text-align: center;
      color: gray;
  }

  .filter_prov_pie{
    width: 40% !important;
  }

  .filter_sert_pie{
    width: 40% !important;
  }

  .filter_sert{
    width: 28% !important;
  }

  .text-landing-grafik {
    color: #001c54;
    font-size: 24px !important;
    font-family: "Poppins", sans-serif !important;
  }
  .getLabel {
    background: #001c54;
    width: max-content;
    border-radius: 8px;
    padding: 10px;
    margin: 10px -10px 10px 10px;
    font-family: "Poppins", sans-serif !important;
    /* margin-left: 56px; */
    display: table-cell;
  }

  .pt-getLabel {
    padding-top: 5px;
  }
  
  .txt-bg-image {
    font-size: 40px !important;
    margin-left: 25% !important;
  }

  .logo-labelmaps {
    margin-top: 10px !important;
  }

  .title-labelmaps {
    margin-top: 13px !important;
  }

  .text-customer {
    margin-left: 20px;
  }
  .button-feedback {
    margin-right: 30px;
  }

  .img-quote {
    position: absolute;
    margin-left: 25%;
  }

  .text-description,
  .user-name,
  .user-job {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Poppins" !important;
    color: #001c54 !important;
  }
}

@media screen and (min-width: 1440px) {
  .txt-bg-image {
    font-size: 40px !important;
    margin-left: 33% !important;
  }

  .logo-labelmaps {
    margin-top: 10px !important;
  }

  .title-labelmaps {
    margin-top: 13px !important;
  }

  .text-description,
  .user-name,
  .user-job {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Poppins" !important;
    color: #001c54 !important;
  }
}

@media screen and (min-width: 2560px) {
  .txt-bg-image {
    font-size: 50px !important;
    margin-left: 35% !important;
  }

  .logo-labelmaps {
    margin-top: 10px !important;
  }

  .title-labelmaps {
    margin-top: 13px !important;
  }

  .text-description,
  .user-name,
  .user-job {
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: "Poppins" !important;
    color: #001c54 !important;
  }
}
