.bg-popup-company { 
    background: #fff !important;
    /* text-align: center !important; */
    width: 100% !important 
  }
  
  .img-popup-company {
    width: 100% !important;
    height: 100px !important;
    border-radius: 3px;
    top: -12px !important;
  }
  
  .name-popup-company { 
    font-size: 13px;
    color: #FC6A03 !important;
    margin-top: 5px !important;
    justify-content: start !important;
    /* text-align: center; */
  }
  
  .part-company {
    justify-content: space-between !important;
  }
  
  .part-2-company {
    justify-content: end !important;
  }
  
  .phone-popup-company {
    color: #FC6A03;
    font-size: 12px;
    /* margin-top: 4px; */
    font-style: italic !important;
    font-weight: 100 !important;
  }
  
  .category-popup-company {
    /* background-color: #FC6A03 !important;
    color: #fff;
    font-size: 11px;
    border-radius: 3px;
    width: 70px;
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    text-align: center; */

    /* background-color: #FC6A03 !important;
    opacity: 0.8;
    border-bottom-left-radius: 27px !important;
    color: #fff;
    font-size: 11px;
    border-radius: 3px;
    width: 70px;
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    text-align: center;
    margin-top: -110px; */

    background-color: #beb6a3 !important;
    opacity: 0.8;
    border-bottom-left-radius: 27px !important;
    color: #000000;
    font-size: 11px;
    border-radius: 3px;
    width: 105px;
    height: 29px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    margin-top: -35px;
    padding-right: 22px;
    position: absolute;
    top: 34px;
    right: -5px;
  }

  .logo-popup-company {
    max-width: 50px;
    width: 100%;
    max-height: 35px;
  }
  
 
  
  .gm-style .gm-style-iw-c {
    border-radius: 25px !important;
    width: 210px !important;
    padding: 0px;
    overflow: hidden !important;
  }
  
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
  
  .gm-ui-hover-effect {
    opacity: .6;
    margin-right: 14px !important;
    color: white !important;
    margin-top: 6px !important;
  }
  
  .marker-btn {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .marker-btn img {
    width: 20px;
    height: 20px;
  }

  .btn-detail-company {
    font-size: 11px;
    background-color: #FC6A03 !important;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 21px;
    border-bottom-right-radius: 21px;
    margin: 3px;
    margin-bottom: 3px;
    width: 97%;
    height: 28px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    cursor: pointer !important;
  }
  
  /* .gm-style .gm-style-iw-t::after {
    background: #FC6A03 !important;
    box-shadow: -2px 2px 2px 0 rgba(178,178,178,.4);
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%,-50%) rotate(-45deg);
    width: 15px;
  } */
  .cGKbZh {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .cGKbZh {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ehkxHv {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .xzcEF {
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }
  
  .ejXxNI { 
    box-shadow: none !important;
  }
  