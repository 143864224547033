.container {
  border-top: 0.4px solid #aaa;
  border-bottom: 1px solid #aaa;
  /* height: 100px; */
  width: 80px;
  cursor: pointer;
}
.cards {
  margin-top: 6px;
  cursor: pointer;
}
.note-wrapper {
  margin-left: 10px;
}
.icon-item {
  border-radius: 70px;
  background-color: #001c54;
  width: "20%";
  padding: 5px;
  height: 30px;
  margin-top: 20px;
}
.place {
  font-size: 17px !important;
  font-weight: 800 !important;
  margin-top: -3px !important;
  margin-right: 18px !important;
  color: #001c54 !important;
  font-family: "Poppins", sans-serif !important;
  margin-left: 10px;
}
.address-wrapper {
  margin-left: 10px !important;
  display: flex !important;
}
.address {
  font-size: 12px !important;
  color: #aaa !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600 !important;
  margin-left: -32px !important;
}
.rate {
  font-size: 12;
}
.star {
  color: #ffe234;
  font-size: small;
}

/* .cardItem{
  display: flex !important;
} */
