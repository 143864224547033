.facility { 
  display: inline-flex !important;
  margin-inline-start: 5px !important 
}

.main-page {
  display: flex !important;
  height: 100vh !important;
  width: 100% !important;
  overflow: hidden !important;
}

.navbar { 
  position: relative !important;
  z-index: 2 !important;
  right: 13px !important;
}

.img-wrap {
  display: flex !important;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
}

.img-place { 
  width: 90%;
  height: 160px 
}

.name-place {
  font-size: 16px;
  margin-left: 15px;
  font-family: "Poppins", sans-serif !important;
  margin-right: 10px;
  color: #001C54;
  font-weight: 600 !important;
}

.info-data {
  font-size: 13px;
  margin-left: 15px;
  font-family: "Poppins", sans-serif !important;
  margin-right: 10px;
  color: #001C54;
}

.icon-data { 
  color: #001C54;
  font-size: 13px;
  top: 0px !important;
  margin-left: 15px !important;
  /* margin-right: 30px  */
}

.button-modal { 
  background: #001C54 !important; 
  border: #001C54 !important 
}



.tittle {
  margin-right: 30px;
}
.bg-input {
  margin-left: 10px;
  background-color: rgb(238, 230, 230);
}
.icons {
  margin-top: 10;
  color: "#444";
  padding-left: 1;
  padding-right: 2;
}
.information {
  font-size: 13px !important;
  font-weight: bold;
  margin-left: 30px;
}
.contain {
  overflow: "hidden";
}
.name-detail {
  font-size: 20px;
  font-weight: 800;
  margin-left: 20px;
  font-family: "Poppins", sans-serif;
}

.body {
  font-size: 14px !important;
}
.loc-detail,
.address-detail {
  /* font-size: 14px !important; */
  margin-right: 40px;
}

.img-certificate {
  height: 60px !important;
  width: "auto" !important;
}



.left {
  width: 25%;
  background: #ffffff !important;
  overflow: hidden !important;
}

.right {
  width: 75% !important;
  overflow: hidden !important;
  height: 100vh;
  bottom: 0 !important;
  z-index: inherit !important;
  position: relative !important;
}

.maps-nav {
  display: none !important;
}

.info-detail,
.info-detail-web {
  margin-top: -10px !important;
  /* font-size: 50px !important;
  font-family: 'Poppins'; */
  font-weight: 600 !important;
}

@media screen and (max-width: 350px) {
  .img-certificate {
    height: 80px !important;
    width: "auto" !important;
  }
  .detail:hover {
    bottom: 80vh !important;
  }
}

@media screen and (max-width: 375px) {
  .img-certificate {
    height: 90px !important;
    width: "auto" !important;
  }
  .detail:hover {
    bottom: 80vh !important;
  }
}

@media screen and (max-width: 425px) {
  .main-page {
    display: inherit !important;
  }

  .left {
    width: 100%;
    display: none !important;
  }

  .right {
    width: 100% !important;
  }

  .detail {
    display: inherit !important;
    background-color: #fff;
    width: 100% !important;
    height: 84vh !important;
    z-index: 2 !important;
    position: relative !important;
    bottom: 250px !important;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
  }

  .detail:hover {
    bottom: 84vh !important;
  }

  .maps-nav {
    display: inherit !important;
    position: relative;
    z-index: 4;
    margin-top: 70px;
  }

  .maps {
    position: relative;
    z-index: 1;
    margin-top: -150px;
  }

  .img-certificate {
    height: 100px !important;
    width: 150px !important;
  }
}

@media screen and (min-width: 768px) {
  .detail {
    display: none !important;
  }

  .navbar-map {
    display: none !important;
  }
  .navbar-list {
    display: inherit !important;
  }

  .info-detail {
    font-size: 12px !important;
  }

  .info-detail-web {
    font-size: 10px !important;
  }
}

@media screen and (min-width: 1024px) {
  .detail {
    display: none !important;
  }

  .navbar-map {
    display: none !important;
  }
  .navbar-list {
    display: inherit !important;
  }
}

@media screen and (min-width: 1440px) {
  .detail {
    display: none;
  }

  .navbar-map {
    display: none !important;
  }
  .navbar-list {
    display: inherit !important;
  }
}

@media screen and (min-width: 2560px) {
  .detail {
    display: none;
  }

  .navbar-map {
    display: none !important;
  }
  .navbar-list {
    display: inherit !important;
  }
}