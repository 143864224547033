.main-page-category-fuel {
  display: flex !important;
  height: 100vh !important;
  width: 100% !important;
  overflow: hidden !important;
}

.left-category-fuel {
  width: 25% !important;
  background: #ffffff !important;
  overflow: hidden !important;
}

.right-category-fuel {
  width: 75% !important;
  overflow: hidden !important;
  height: 100vh;
  z-index: inherit !important;
  position: relative !important;
}

.detail-category-fuel {
  display: inherit;
}

.tittle {
  margin-right: 30px;
}
.bg-input {
  margin-left: 10px;
  background-color: rgb(238, 230, 230);
}
.icons {
  margin-top: 10;
  color: "#444";
  padding-left: 1;
  padding-right: 2;
}
.information-fuel {
  font-size: 10px !important;
  font-weight: bold;
  margin-left: 30px;
}
.contain {
  overflow: hidden;
}

.nearby {
  /* color: #001c54 !important; */
  font-family: "Poppins" !important;
  font-weight: 900 !important;
}

@media screen and (max-width: 700px) {
  .main-page-category-fuel {
    display: inherit !important;
  }

  .left-category-fuel {
    width: 100%;
    display: none !important;
  }

  .right-category-fuel {
    width: 100% !important;
  }

  .view-list-fuel {
    width: 100px !important;
    height: 50px !important;
    background: #ffffff;
    z-index: 3 !important;
    bottom: 50px !important;
    left: 20px !important;
    position: relative !important;
    left: 0 !important;
  }

  .detail-category-active-fuel {
    width: 100% !important;
    background: #ffffff;
    height: 93vh !important;
    z-index: 3 !important;
    bottom: 93vh !important;
    position: relative !important;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }

  .text-view-list-fuel,
  .text-view-map-fuel {
    font-weight: bold;
    color: #001c54;
    text-align: center !important;
    font-size: 16px;
    line-height: 50px !important;
  }

  .view-map-fuel {
    width: 100% !important;
    height: 50px !important;
    background: #ccc;
    bottom: 0 !important;
  }

  .text-view-map-fuel {
    font-weight: bold;
    color: #001c54;
    text-align: center !important;
    font-size: 14px;
    line-height: 50px !important;
  }

  .maps-category-fuel {
    position: relative;
    z-index: 1;
    margin-top: -150px;
  }

  .maps-nav-category-fuel {
    display: inherit !important;
    position: relative;
    z-index: 3;
    margin-top: 70px;
    margin-left: -20px !important;
  }

  .search-fuel {
    z-index: 2 !important;
    margin-top: 80px !important;
    position: relative !important;
    /* margin-left: 0 !important; */
  }

  .filter-category-fuel {
    margin-left: 20% !important;
  }
}

@media screen and (min-width: 768px) {
  .left-category-fuel {
    width: 30% !important;
  }

  .right-category-fuel {
    width: 70% !important;
  }

  .detail-category-fuel {
    display: none !important;
  }

  .maps-nav-category-fuel,
  .search-fuel,
  .filter-category-fuel {
    display: none !important;
  }

  .nearby {
    font-size: 9px !important;
    margin-top: 14px !important;
    margin-left: -3px !important;
  }
}

@media screen and (min-width: 1024px) {
  .detail-category-fuel {
    display: none !important;
  }

  .maps-nav-category-fuel,
  .search-fuel,
  .filter-category-fuel {
    display: none !important;
  }

  .nearby {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 1440px) {
  .detail-category-fuel {
    display: none !important;
  }

  .maps-nav-category-fuel,
  .search-fuel,
  .filter-category-fuel {
    display: none !important;
  }
}

@media screen and (min-width: 2560px) {
  .detail-category-fuel {
    display: none !important;
  }

  .maps-nav-category-fuel,
  .search-fuel,
  .filter-category-fuel {
    display: none !important;
  }
}
